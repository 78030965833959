/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import ContentPageTemplate from "../templates/content-page";
const MDXLayout = ContentPageTemplate;
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "Heilhypnose"), "\n", React.createElement(_components.p, null, "Bei der Heilhypnose handelt es sich um eine natürliche Methode, die zur Entspannung und Ruhe im Körper beiträgt und positive Gefühle hervorruft. Sie ist ein Ruhezustand, in dem das Unbewusste zugeschaltet ist und alles was ich Ihnen sage, dort gespeichert wird. Alle positiven Formeln sind dort abrufbereit für Sie da."), "\n", React.createElement(_components.p, null, "Der Erfolg der Heilhypnose besteht darin, durch entsprechende Formeln Ihrem Unbewussten im positivem Sinne neue Impulse zu geben, die es später umsetzen wird. Sie trägt dazu bei, dieses Unbewusste positiv zu stärken und es in unseren Alltag so zu integrieren, dass wir gestärkt daraus hervorgehen. Eine Therapieeinheit dauert im Schnitt etwa 40 Minuten. Die Heilhypnose kann auf alle Organe im menschlichen Körper Einfluss nehmen."));
}
function MDXContent(props = {}) {
  return React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props));
}
export default MDXContent;
